<template>
    <div class="operPanel" v-show="visible"  v-bind:id="'operPanel'" :style="posStyle">
        <div @mousedown="mouseDown"
             @touchstart.stop="touchDown" @touchmove.stop="touchMove" @touchend.stop="mouseUp" style="width: 20px;background-color: #3087FF;"></div>
        <div v-for="item in btnData" class="btn" v-if="item.visible" @click.stop="myClick(item)" @mouseenter.stop="changeImageSrc(item)" @mouseleave.stop="imageLeave(item)" :style="getStyle(item)">

            <el-tooltip :content="item.tip" placement="bottom" effect="light">
                <img :src="(item.hover || item.isSel)?item.imageSel:item.image"  />
            </el-tooltip>
        </div>
    </div>

</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'operPanel',
        data(){
            return {
                formLabelWidth: '120px', moveFlag: false, dx: 0, dy: 0,
                loading: false,
                btnData: [
                    {seq: 1, image: require("assets/img/scoring/zhengque.png"), visible: true, isSel: false, hover: false, tip: '正确', imageSel: require("assets/img/scoring/zhengque_sel.png"), },
                    {seq: 11, image: require("assets/img/scoring/bandui.png"), visible: true, isSel: false, hover: false, tip: '一半正确', imageSel: require("assets/img/scoring/bandui_sel.png"), },
                    {seq: 2, image: require("assets/img/scoring/cuowu.png"), visible: true, isSel: false, hover: false, tip: '错误', imageSel: require("assets/img/scoring/cuowu_sel.png"), },
                    {seq: 15, image: require("assets/img/scoring/wen.png"), visible: true, isSel: false, hover: false, tip: '问号',imageSel: require("assets/img/scoring/wen_sel.png"), },
                    {seq: 16, image: require("assets/img/scoring/shenglue.png"), visible: true, isSel: false, hover: false, tip: '省略号',imageSel: require("assets/img/scoring/shenglue_sel.png"), },
                    {seq: 12, image: require("assets/img/scoring/yi.png"), visible: true, isSel: false, hover: false, tip: '划线',imageSel: require("assets/img/scoring/yi_sel.png"), },
                    {seq: 3, image: require("assets/img/scoring/yuanquan.png"), visible: true, isSel: false, hover: false, tip: '画圆', imageSel: require("assets/img/scoring/yuanquan_sel.png"), },
                    {seq: 14, image: require("assets/img/scoring/juxing.png"), visible: true, isSel: false, hover: false, tip: '矩形', imageSel: require("assets/img/scoring/juxing_sel.png"), },
                    {seq: 4, image: require("assets/img/scoring/wenzi.png"), visible: true, isSel: false, hover: false, tip: '添加文字', imageSel: require("assets/img/scoring/wenzi_sel.png"), },
                    {seq: 5, image: require("assets/img/scoring/xiangpica.png"), visible: true, hover: false, tip: '需选中某个痕迹，再点橡皮擦删除', imageSel: require("assets/img/scoring/xiangpica_sel.png"), },
                    // {seq: 7, image: require("assets/img/scoring/huabi.png"), isSel: false, hover: false, imageSel: require("assets/img/scoring/huabi_sel.png"), },
                    {seq: 8, image: require("assets/img/scoring/lajitong.png"), visible: true, hover: false, tip: '删除全部痕迹', imageSel: require("assets/img/scoring/lajitong_sel.png"), },
                    // {seq: 9, image: require("assets/img/scoring/jt_up.png"), visible: false, hover: false, imageSel: require("assets/img/scoring/jt_up_sel.png"), },
                    // {seq: 10, image: require("assets/img/scoring/jt_down.png"), visible: false, hover: false, imageSel: require("assets/img/scoring/jt_down_sel.png"), }
                    // {seq: 11, image: require("assets/img/scoring/jt_left.png"), hover: false, imageSel: require("assets/img/scoring/jt_left_sel.png"), },
                    // {seq: 12, image: require("assets/img/scoring/jt_right.png"), hover: false, imageSel: require("assets/img/scoring/jt_right_sel.png"), },
                    // {seq: 14, image: require("assets/img/scoring/delItem.png"), hover: false, imageSel: require("assets/img/scoring/delItem_sel.png"), },

                ],
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
            posStyle(){
                return {left: this.x+"px", top: this.y+"px"};
            }
        },

      	mounted() {

	    },
        props: {
            visible: {
                seq: Boolean
            },
            x: Number,
            y: Number,
            status: String,
        },
        watch: {
            status: {
                deep: true, immediate: true,
                handler(n, o) {
                    // if(n=='YY'){
                    //     this.btnData[8].visible = true;
                    //     this.btnData[9].visible = true;
                    // }else{
                    //     this.btnData[8].visible = false;
                    //     this.btnData[9].visible = false;
                    // }
                }
            }
        },
        methods: {
            mouseDown(event) {
                this.selectElement = document.getElementById('operPanel');
                var div1 = this.selectElement
                this.selectElement.style.cursor = 'move'
                this.isDowm = true
                var distanceX = event.clientX - this.selectElement.offsetLeft
                var distanceY = event.clientY - this.selectElement.offsetTop
                document.onmousemove = function (ev) {
                    var oevent = ev || event
                    div1.style.left = oevent.clientX - distanceX + 'px'
                    div1.style.top = oevent.clientY - distanceY + 'px'
                }
                document.onmouseup = function () {
                    document.onmousemove = null
                    document.onmouseup = null
                    div1.style.cursor = 'default'
                }
            },

            touchDown(event) {
                this.moveFlag = true;
                this.selectElement = document.getElementById('operPanel');
            },
            touchMove(event){
                if(this.moveFlag){
                    var div1 = this.selectElement;
                    var touch;
                    if(event.touches) touch = event.touches[0]; else touch = event;
                    var distanceX = touch.clientX;
                    var distanceY = touch.clientY;
                    div1.style.left = distanceX + 'px'
                    div1.style.top = distanceY + 'px'

                }
            },
            mouseUp(){
                if(this.moveFlag){
                    this.moveFlag = false;
                    // this.selectElement.style.cursor = 'default'
                }
            },
            changeImageSrc(item) {
                this.imageLeave(item);
                item.hover = true;

            },
            imageLeave(item) {
                this.btnData.forEach((item, index) => {
                    item.hover = false;
                });

            },
            getStyle(item){
                if(item.hover || ("isSel" in item && item.isSel)){
                    return {backgroundColor: '#02b6fd'};
                }
                return null;
            },
            unSelAll(){
                this.btnData.forEach((t, index) => {
                    if("isSel" in t){
                        t.isSel = false;
                    }
                });
            },
            myClick(item){
                if("isSel" in item) {
                    let isSel = !item.isSel;
                    this.btnData.forEach((t, index) => {
                        if ("isSel" in t) {
                            t.isSel = false;
                        }
                    });
                    item.isSel = isSel;
                    this.$emit('ok', isSel ? item.seq : -1);
                    return;
                }
                if(item.seq == 8){
                  this.btnData.forEach((t, index) => {
                    if ("isSel" in t) {
                      t.isSel = false;
                    }
                  });
                }
                this.$emit('ok', item.seq);
            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .operPanel{
        position: absolute;
        top: 90%;
        left: 40%;
        text-align: center;
        display:flex;
        justify-content:center;
        z-index: 999;
        height: 50px;
        .btn {
            padding: 5px;
            float: left;
            border: 1px solid rgba(203, 202, 202, 0.71);
            img {
                width: 40px;
                height: 40px;
            }
        }
    }


</style>


