<template>
    <div class="searchDialog">
        <el-dialog title="学生搜索" width="450px" :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :model="searchForm"  ref="searchDialogForm" :rules="searchRules">
                
                <el-form-item label="分数从"  prop="scoreFrom" :label-width="formLabelWidth">
                    <el-input v-model="searchForm.scoreFrom" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="到"  prop="scoreTo" :label-width="formLabelWidth">
                    <el-input v-model="searchForm.scoreTo" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="学号"  prop="studentNo" :label-width="formLabelWidth">
                    <el-input v-model="searchForm.studentNo"  autocomplete="off" ></el-input>
                </el-form-item>
                <el-form-item label="姓名"  prop="studentName" :label-width="formLabelWidth">
                    <el-input v-model="searchForm.studentName"  autocomplete="off" ></el-input>
                </el-form-item>
                <el-form-item label="题目"  prop="studentName" :label-width="formLabelWidth">
                  <el-select v-model="searchForm.examSeq" placeholder="请选择">
                    <el-option :key="null" label="全部" :value="null"></el-option>
                    <el-option v-for="item in searchIxList" :key="item.exam_seq" :label="item.exam_seq" :value="item.exam_seq"></el-option>
                  </el-select>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'searchDialog',
        data(){
            return {
                formLabelWidth: '80px',
                dialogVisible:false, loading: false,
                roleList: [], selRoleList: [],
                searchForm:{ scoreFrom: 0, scoreTo: 100, searchText: null, studentNo: null, studentName: null, examSeq: null },
                searchIxList: null,
                searchRules: {
                    scoreFrom: [{ required: true, message: '请填写分数范围', trigger: 'blur' }],
                    scoreTo: [{ required: true, message: '请填写分数范围', trigger: 'blur' }],
                },
            }
        },
        components:{
        },
        computed:{
        },
      	mounted() {

	    },

        methods: {
            showDialog(searchIxList){
                this.searchForm = { scoreFrom: 0, scoreTo: 100, searchText: null, examSeq: null};
                this.searchIxList = searchIxList;
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },

            submitForm(){

                console.log(this.searchForm);
                let that = this;
                this.$refs.searchDialogForm.validate((valid) => {
                    if(valid){
                        that.$emit('ok', this.searchForm);
                        that.dialogVisible = false;
                    }
                });

            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .searchDialog{

    }


</style>


