<template>
    <div class="rightPanel" v-show="visible">

        <el-card class="box-card">

            <el-row>
                <el-col :span="8">
                    <a  v-if="topicData && topicData.length > 1 && topicMode==1" style="background-color: #02b6fd; padding: 4px 8px; color: white; font-size: 14px; cursor:pointer " @click="makeScore(1)">全部满分</a>
                </el-col>
                <el-col :span="8">
                    <a  v-if="topicData && topicData.length > 1 && topicMode==1" style="background-color: red; padding: 4px 8px; color: white; font-size: 14px;cursor:pointer " @click="makeScore(0)">全部零分</a>
                </el-col>
                <el-col :span="8" v-if="showAllExam">
                    <a style="background-color: #67C23A; padding: 4px 8px; color: white; font-size: 14px;cursor:pointer " @click="setShowAllExam">查看原卷</a>
                </el-col>

            </el-row>
            <el-divider></el-divider>
            <div class="score-list" :style="scoreListHeight">
                <el-row class="head" v-if="topicData && topicData.length > 1">
                    <el-col :span="4">
                        <span>块得分:</span>
                    </el-col>
                    <el-col :span="8">
                        <span>{{seqTotalScore}}</span>
                    </el-col>
                    <el-col :span="6" v-if="topicType=='FillBlank' && topicMode==2">
                      <a  style="background-color: #02b6fd; padding: 4px 2px; color: white; font-size: 14px; cursor:pointer; border-radius: 6px " @click='quickScoreDefault'>打分框复原</a>
                    </el-col>
                </el-row>
                <el-row class="head">
                    <el-col :span="4">
                        <span>题号</span>
                    </el-col>
                    <el-col :span="8">
                        <span>得分</span>
                    </el-col>
                    <el-col :span="3">
                        <span>满分</span>
                    </el-col>
                    <el-col :span="8" v-if="topicData && topicData.length > 1 && topicMode==1">
                        <el-checkbox v-model="autoNext">自动跳题</el-checkbox>
                    </el-col>
                  <el-col :span="8" v-if="(!topicData || topicData.length == 1) && topicMode==1">
<!--                    <el-checkbox v-model="autoSubmit" style="color: red;">自动提交分数</el-checkbox>-->
<!--                    <el-checkbox v-model="nocheckzero">0分无需确认</el-checkbox>-->
                      <div class="checkbox-wrapper">
                        <el-checkbox v-model="autoSubmit" style="color: red;">自动提交分数</el-checkbox>
                      </div>
                      <div class="checkbox-wrapper">
                        <el-checkbox v-model="nocheckzero">0分无需确认</el-checkbox>
                      </div>
                  </el-col>

                </el-row>
                <el-row v-for="(item, index) in topicData" class="score-row">
                    <el-col :span="4">
                        <span class="text" v-if="selTopic!=null && selTopic.topicIndex==item.topicIndex" style="color: red;">*</span>
                        <span class="text" :style="(selTopic!=null && selTopic.topicIndex==item.topicIndex)?{color: 'red'}: {}">{{item.topicNo}}</span>
                    </el-col>
                    <el-col :span="8">
                        <el-input v-model="item.score" placeholder="分数" @focus="scoreFocus(item)" @keydown.native="scoreKeyDown" style="width: 80px;"></el-input>
                    </el-col>
                    <el-col :span="3">
                        <span class="text">{{item.rightScore}}</span>
                    </el-col>

                    <el-col :span="4" v-if="topicMode==1">
                        <el-button type="text" size="mini" @click="setScore1(item, item.rightScore)">满分</el-button>
                    </el-col>
                    <el-col :span="4" v-if="topicMode==1">
                        <el-button type="text" size="mini" @click="setScore1(item, 0)">零分</el-button>
                    </el-col>

                </el-row>
            </div>
            <div class="board-list">
                <el-row>
                    <el-col :span="24" >
                        <el-radio-group v-model="topicMode" @change="topicModeChange">
                            <el-radio :label="1">直接打分</el-radio>
                            <el-radio :label="2">踩点打分</el-radio>
                            <el-radio v-if="selTopic!=null && selTopic.topicType!='FillBlank'" :label="3">多维度打分</el-radio>
                        </el-radio-group>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 10px;">
                    <el-col :span="24" v-if="topicMode==1" style="line-height: 30px;" >
                        <span style="line-height: 30px;">步长：</span>
                        <el-select v-model="caidianStep" placeholder="请选择步长" @change="stepChange" style="line-height: 30px;width: 70px;">
                            <el-option v-for="item in caidianStepList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="6" v-if="topicMode==1" v-for="item in scoreList" >
                        <el-button plain class="score-btn" @click="setScore(item)">{{item.value}}</el-button>
                    </el-col>
                    <el-col :span="14" v-if="topicMode==2"  >
                        <el-radio-group v-model="caidianMode" @change="caidianChange">
                            <el-radio :label="0" class="radio-btn" >加分</el-radio>
                            <el-radio :label="1" class="radio-btn" >减分</el-radio>
                        </el-radio-group>
                    </el-col>
                    <el-col :span="10" v-if="topicMode==2" style="line-height: 30px;" >
                        <span style="line-height: 30px;">步长：</span>
                        <el-select v-model="caidianStep" placeholder="请选择步长" @change="stepChange" style="line-height: 30px;width: 70px;">
                            <el-option v-for="item in caidianStepList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="24" v-if="topicMode==2"  >
                        <el-radio-group v-model="topicValue" @change="topicValueChange">
                            <el-radio-button :label="caidianMode==0?item.value:(0-item.value)"  class="radio-btn" v-for="item in scoreList">{{caidianMode==0?item.value:(item.value==0?"0":("-"+item.value))}}</el-radio-button>
                        </el-radio-group>
                    </el-col>
                    <div v-if="topicMode==3">
                        <el-row style="margin-top: 10px;">
                            <el-col :span="12" >
                                <el-button type="primary" plain  @click="addSteps()">添加评分维度</el-button>
                            </el-col>
                            <el-col :span="12" style="line-height: 30px;" >
                                <span style="line-height: 30px;">步长：</span>
                                <el-select v-model="caidianStep" placeholder="请选择步长" @change="stepChange" style="line-height: 30px;width: 70px;">
                                    <el-option v-for="item in caidianStepList" :key="item" :label="item" :value="item">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row style="margin-top: 10px; font-weight: bold;">
                            <el-col :span="4">
                                <span>序号</span>
                            </el-col>
                            <el-col :span="7">
                                <span>步骤名</span>
                            </el-col>
                            <el-col :span="7">
                                <span>分数</span>
                            </el-col>
                        </el-row>
                        <el-row v-for="(item, index) in bySteps" style="margin-top: 8px;">
                            <el-col :span="4">
                                {{index+1}}、
                            </el-col>
                            <el-col :span="7"><el-input v-model="item.name"  style="width: 80px;"></el-input></el-col>
                            <el-col :span="7">
                                <el-input v-model="item.score" placeholder="得分" @focus="setStepFocus(item)" @blur="stepBlur" style="width: 80px;"></el-input>
                            </el-col>
                            <el-col :span="4" >
                                <el-button type="text" size="mini" @click="delStep(index)">删除</el-button>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6" v-if="topicMode==3 && bySteps.length >0" v-for="item in scoreList" >
                                <el-button  plain class="score-btn" @click="setStepScore(item)">{{item.value}}</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </el-row>
            </div>
            <el-row style="margin-top: 25px;">
                <el-col :span="12">
                    <el-button type="primary" :loading="loading" @click='submitForm'>提交分数</el-button>
                </el-col>
                <el-col :span="12">
                    <el-button type="warning"  @click='toLast'>延迟阅卷</el-button>
                </el-col>
            </el-row>

        </el-card>
    </div>

</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'rightPanel',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null, scoreListHeight:{}, isQuickScore: false,//是否快捷打分，填空题
                loading: false, topicData: [], zgIds: [],  caidianStepList: [], status: null,
                topicMode: 1, topicType: null, topicValue: null, caidianMode: 0, caidianStep: 1, autoNext: false, autoSubmit: false,
              nocheckzero:false,
                fullScore: 0, scoreList: [], selTopic: null, bySteps: [], selStep: null,
                examId: null, examSeq: null, markRecords: null,
            }
        },
        components:{
        },
        created() {
            this.caidianStepList.push(0.25);
            this.caidianStepList.push(0.5);
            this.caidianStepList.push(1);
            this.caidianStepList.push(2);
            this.caidianStepList.push(3);
            this.caidianStepList.push(4);
            this.caidianStepList.push(5);
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
            seqTotalScore(){
                if(this.topicData && this.topicData.length > 1){
                    let score = 0;
                    for (let i=0; i<this.topicData.length; i++) score = score+ XEUtils.toNumber(this.topicData[i].score);
                    return score;
                }
                return 0;
            },
        },
      	mounted() {
            let myHeight = document.documentElement.clientHeight ;
            this.scoreListHeight = {maxHeight: (myHeight*0.4)+'px'};

	    },
        props: {
            zgInfo: {
                type: Object
            },
            visible: {
                type: Boolean
            },
            showAllExam: {
                type: Boolean
            },
            isEdit: {
              type: Boolean, default: false
            },
        },
        watch: {
            zgInfo: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log("zgInfo", n)
                    let that = this;
                    if(n){
                        this.examId = n.examId;
                        if(this.examSeq != n.examSeq) {
                            this.autoNext = false;
                            this.autoSubmit = false;
                        }
                        if(n.isQuickScore) this.isQuickScore = n.isQuickScore
                        this.status = n.status;
                        this.examSeq = n.examSeq;
                        this.zgIds = n.zgIds;
                        this.markRecords = null;
                        if(n.markRecords != null && n.markRecords[0] != null){
                            console.log('markRecords', n.markRecords);
                            this.markRecords = JSON.parse(n.markRecords[0]);
                        }
                        this.loadTopicList(function () {
                            that.fillScoreInfo(function (){
                              if(that.topicType=='FillBlank' && that.topicMode==2){
                                // that.$emit('quickScore', {show: true, topicType: that.topicType, topicData: that.topicData});
                              }
                              // setTimeout(function (){
                              //   console.log("show topicData", that.topicData)
                              // }, 2000)
                            });
                        });
                    }

                }
            },
            selTopic: {
                deep: true, immediate:true,
                handler(n, o){
                    if(n!=null && n.rightScore>0){
                        this.rightScore = n.rightScore;
                        this.stepChange();
                    }
                }
            },
            topicMode: {
                deep: true, immediate:true,
                handler(n, o){
                    if(n!=2){
                      this.setExamMode(-1, null);
                    }
                    let showQuick = false;
                    if(this.topicType=='FillBlank' && n==2 && this.status != 'YY') showQuick = true
                    this.$emit('quickScore', {show: showQuick, topicType: this.topicType, topicData: this.topicData});
                }
            },

        },
        methods: {
          reEdit(){
            this.dialogVisible = false;
            this.$emit('reEdit');
          },
          quickScoreChange(v){
            this.isQuickScore = v
            // if(v) this.topicMode = 2
            this.$emit('quickScore', {show: v, topicType: this.topicType, topicData: this.topicData});
          },
          quickScoreDefault(){
            console.log("quickScoreDefault....")
            this.$emit('quickScore', {show: true, reset: true, topicType: this.topicType, topicData: this.topicData});
          },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
          topicModeChange(v){
            // console.log("topicModeChange", v)
            if(v == 2){
              this.selTopic.score = 0
            }
          },
            stepChange(){
                this.scoreList = [];
                let rightScore = this.selTopic.rightScore;
                let stepCount = Math.round(rightScore / this.caidianStep);
                console.log("stepChange", stepCount, rightScore);
                for (let i=stepCount; i>0; i--){
                    this.scoreList.push({topicIndex: this.selTopic.topicIndex, rightScore: this.selTopic.rightScore, value: rightScore});
                    rightScore = rightScore - this.caidianStep;
                    if(rightScore <=0) rightScore = 0;
                }

                this.scoreList.push({topicIndex: this.selTopic.topicIndex, rightScore: this.selTopic.rightScore, value: 0});
            },
            loadTopicList(callback){
                let that = this;
                this.topicData = [];
                let seqs = this.examSeq.split(",");
                let search = { examId: this.examId, topicIndexs: seqs};
                let param = { controllerName: 'akExamTopic', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadTopicList", data)
                    if (data.code == 0) {
                        let list = data.result;
                        for (let i=0; i<list.length; i++){
                            let d = list[i];
                            that.topicType = d.topic_type;
                            let tfind = XEUtils.find(that.topicData, item => item.topicIndex == d.topic_index);
                            if(!tfind){
                                let o = {topicIndex: d.topic_index, topicNo: d.topic_no, topicType: d.topic_type, rightScore: d.score, score: 0, };
                                that.topicData.push(o);
                            }
                        }
                        if(that.status != 'YY' && that.isQuickScore && that.topicType=='FillBlank') that.quickScoreChange(that.isQuickScore)
                        this.selTopic = that.topicData[0];
                        that.scoreFocus(this.selTopic);
                        if(this.caidianMode ==1 ){ //如果踩点打分是减分，默认是满分
                            this.selTopic.score = this.selTopic.rightScore;
                        }
                        if(callback) callback();
                    }
                    that.loading = false;
                }).catch((error) => {
                    console.log("error", error)
                });
            },
            addSteps(){
                this.bySteps.push({name: '维度'+(this.bySteps.length+1), score: 0});
                this.selStep = this.bySteps[this.bySteps.length-1];
            },
            delStep(index){
                this.bySteps.splice(index, 1);
            },
            setScore1(item, v){
                this.selTopic = item;
                this.selTopic.score = v;
            },
            setScore(item){
                this.selTopic.score = item.value;
                if(this.autoSubmit){
                  debugger
                  this.jumpAutoSubmit();
                  return ;
                }
                this.jumpNext();
            },
            caidianChange(v){
                console.log("caidianChange", v, this.selTopic);
                // if(v == 0 && this.selTopic != null ){
                //     this.selTopic.score = 0;
                // }
                // if(v == 1 && this.selTopic != null ){
                //     this.selTopic.score = this.selTopic.rightScore;
                // }
            },
            fillScoreInfo(callback){
                console.log("fillScoreInfo", this.markRecords, this.topicData);
                if(this.markRecords !=null && "scoreInfo" in this.markRecords){
                    let scoreInfo = this.markRecords["scoreInfo"];
                    let topicData = scoreInfo.topicData;
                    this.topicData.forEach((t, index) => {
                        let tfind = XEUtils.find(topicData, item => {
                            return item.topicIndex == t.topicIndex;
                        })
                        if(tfind){
                          t.score = XEUtils.toNumber(tfind.score);
                          console.log("match score", t.score, tfind)
                        }

                    });
                    let setting = scoreInfo["setting"];
                    if(("topicMode" in setting)) this.topicMode = setting.topicMode; else this.topicMode = 0;
                    if("bySteps" in setting) this.bySteps = setting.bySteps==null?[]:setting.bySteps;
                    if("caidianMode" in setting) {
                      this.caidianMode = scoreInfo.caidianMode;
                    }else this.caidianMode = 0;
                    if("caidianStep" in setting) this.caidianStep = scoreInfo.caidianStep; else this.caidianStep = 1;
                    this.topicValue = null;
                    if(callback) callback()
                }else{
                    // this.topicMode = 0;
                    // this.bySteps = [];
                    // this.caidianMode = 0;
                    // this.caidianStep = 1;
                    // this.topicValue = null;
                }
            },
            jumpAutoSubmit(){
              if(this.autoSubmit){
                this.submitForm();
              }
            },
            jumpNext(){
                if(this.autoNext){
                    if(this.selTopic == null){
                        this.selTopic = this.topicData[0];
                    }else{
                        let fidx = XEUtils.findIndexOf(this.topicData, item => item.topicIndex == this.selTopic.topicIndex);
                        if(fidx > -1){
                            if(fidx < this.topicData.length-1){
                                this.selTopic = this.topicData[fidx+1];
                            }else{
                                this.selTopic = this.topicData[0];
                            }
                        }
                    }
                    this.setExamMode(99, this.topicValue);
                }
            },
            caidianScore(topicIndex, v, isReset, isTopicValue){ //踩点打分，添加分数
                console.log("caidianScore", topicIndex, v, isReset, isTopicValue);
                let fidx = XEUtils.find(this.topicData, item => item.topicIndex == topicIndex);
                if(fidx){
                    if(isTopicValue) this.topicValue = null;
                    if(isReset){
                        fidx.score = 0;
                        // return true;
                    }
                    let s = XEUtils.toNumber(fidx.score);
                    s = s + v;
                    if(s < 0) return false;
                    if(s > fidx.rightScore) return false;
                    fidx.score = s;
                    this.jumpNext();
                }
                return true;
            },
            topicValueChange(v){
                this.setExamMode(99, this.topicValue);
            },
            makeTopicValue(v){
                if(this.topicMode==2){
                    console.log("setTopicValue", v);
                    this.topicValue = v;
                }
            },
            scoreFocus(item){
                this.selTopic = item;
                if(this.topicMode==2){
                    this.setExamMode(99, this.topicValue);
                }
            },
            scoreFocusByIndex(idx){
              this.selTopic = this.topicData[idx];
            },
            setStepFocus(item){
                this.selStep = item;
            },
            setStepScore(item){
                if(this.selStep != null) this.selStep.score = item.value;
                if(this.topicMode == 3 && this.selTopic != null && this.bySteps.length > 0){
                    this.selTopic.score = 0;
                    this.bySteps.forEach((t, index)=>{
                        this.selTopic.score = this.selTopic.score + XEUtils.toNumber(t.score);
                    })
                }
            },
            stepBlur(){
                if(this.topicMode == 3 && this.selTopic != null && this.bySteps.length > 0){
                    this.selTopic.score = 0;
                    this.bySteps.forEach((t, index)=>{
                        this.selTopic.score = this.selTopic.score + XEUtils.toNumber(t.score);
                    })
                }
            },
            clearScore(){
              console.log('clear score......')
                this.topicData.forEach((item, index) => {
                    item.score = 0;
                });
              // if(this.topicType=='FillBlank' && this.topicMode==2) this.quickScoreDefault()
            },
            makeScore(v){
              console.log('make score......')
                for (let i=0; i<this.topicData.length; i++){
                    let t = this.topicData[i];
                    if(v ==0) t.score = 0;
                    if(v ==1) t.score = t.rightScore;
                }
            },
            scoreKeyDown(e){
                if(this.topicMode ==1 || this.topicMode ==3){
                    let key = e.key;
                    if(key == '1' || key == '2' || key == '3' || key == '4' || key == '5' || key == '6' || key == '7' || key == '8' || key == '9' || key == '0' || key == '.'){
                        return true;
                    }else if( key == 'Enter'){
                      this.$confirm(`确定提交当前分数吗？`, '提示', {
                        confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                      }).then(() => {
                        this.submitForm()
                      }).catch((err) => {
                        console.log("err", err);
                      });
                    }
                }
                e.returnValue = false;
                return false;
            },
            setShowAllExam(){
                this.$emit('okExam');
            },
            setExamMode(mode, myValue){
                let o = null;
                if(this.selTopic != null) o = {topicIndex: this.selTopic.topicIndex, rightScore:this.selTopic.rightScore,  myScore: myValue, step: this.caidianStep};
                this.$emit('changeMode', mode, o);
            },
            validate(){
                if(this.topicMode ==1 || this.topicMode ==2 || this.topicMode ==3){
                    for (let i=0; i<this.topicData.length; i++){
                        let t = this.topicData[i];
                        let myScore = XEUtils.toNumber(t.score);
                        if(myScore < 0){
                            this.$alert('第' + t.topicIndex +'题 分数不能小于0分', '错误');
                            return false;
                        }
                        if(myScore > t.rightScore){
                            this.$alert('第' + t.topicIndex +'题 分数不能超过'+t.rightScore+'分', '错误');
                            return false;
                        }
                    }
                }

                return true;
            },
            toLast(){

                let param = { controllerName: 'examTask', methodName: '/updateUddt', param: {zgIds: this.zgIds, status: '02'}};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    console.log('submitUddt', code);
                    if (code == 0) {
                        this.$message({message: '暂阅成功！', type: 'success', duration: 2000});
                        this.$emit('submitNext');
                    }else{
                        this.$message({message: '暂阅失败！', type: 'error', duration: 2000});
                    }

                });

            },
            submitForm(){

                if(!this.validate()){
                    return ;
                }
                let score = 0;
                let myTopicData = [];
                for (let i=0; i<this.topicData.length; i++){
                    let d = this.topicData[i];
                    let fidx = XEUtils.find(myTopicData, item => item.topicIndex == d.topicIndex);
                    if(!fidx){
                        myTopicData.push(d);
                        score = score+ XEUtils.toNumber(d.score);
                    }
                }
              let that = this;
              if(score == 0 && !this.nocheckzero){
                this.$confirm("该学生该题为0分，确认提交？", '提示', {
                  confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {
                  let o = {topicData: myTopicData, score: score, caidianMode: that.caidianMode, caidianStep: that.caidianStep,
                    setting: { topicMode: that.topicMode, autoNext: that.autoNext, bySteps: that.bySteps} }
                  console.log('submitForm 000 ', o);
                  that.loading = true;
                  that.$emit('submitScore', o);
                }).catch((err) => {
                  console.log("err", err);
                });
              }else {

                let o = {topicData: myTopicData, score: score, caidianMode: this.caidianMode, caidianStep: this.caidianStep,
                    setting: { topicMode: this.topicMode, autoNext: this.autoNext, bySteps: this.bySteps} }
                console.log('submitForm', o);
                this.loading = true;
                this.$emit('submitScore', o);
              }


            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .checkbox-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .rightPanel{
        position: absolute;
        top: 70px;
        right: 0;
        .text {
            font-size: 14px;
        }
        .text-active {
            color: red;
        }
        .item {
            margin-bottom: 18px;
        }
        .box-card {
            width: 380px;
        }
        .score-list {
            overflow-y: auto;
            .head{
                font-weight: bold;
                margin-bottom: 8px;
            }
            .score-row {
                .text{
                    line-height: 30px;
                }
            }
        }
        .board-list {
            margin-top: 15px;
            .score-btn {
                width: 68px;
                margin-top: 8px;
            }
            .radio-btn {
                width: 60px;
                margin-top: 8px;
                margin-right: 8px;

                .el-radio-button__inner {
                    color: black;
                    border: 1px solid #DCDFE6;
                    border-radius: 4px;
                    font-size: 14px;
                }
            }
            .radio-fu {

                .el-radio-button__inner {
                    color: red;
                }
            }
        }
    }


</style>


