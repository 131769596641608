<template>
    <div class="recentList">
        <div class="table_container">
            <el-table v-loading="loading" :data="tableData" style="width: 100%" align='center'>
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column prop="operation" align='center' label="操作">
                    <template slot-scope='scope'>
                        <el-button icon='edit' type="primary" size="mini" @click='openRecent(scope.row)'>查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="student_no" label="学号" width="110" align='center' ></el-table-column>
                <el-table-column prop="student_name" label="姓名" align='center' ></el-table-column>
                <el-table-column prop="exam_seq" label="题号" align='center' ></el-table-column>
                <el-table-column prop="score" label="分数" align='center' ></el-table-column>
            </el-table>
        </div>

    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";

    export default {
        name: 'recentList',
        data(){
            return {
                tableData: [], tableHeight:0,
                loading:true, isShow:false,
                selRecent: null, selRecentDtl: null,
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, schoolId: null, professionId: null},
            }
        },
        components:{
            Pagination
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
        props: {
            examId: {
                type: Number
            },
            examSeq: {
                type: String
            },
            show: {
                type: Boolean
            },
        },
        watch: {
            show: {
                deep: true, immediate: true,
                handler(n, o) {
                    if(n){
                        this.loadRecentList();
                    }
                }
            }
        },
      	mounted() {

	   },
        methods: {

            loadRecentList(){
                this.loading = true;
                let search = { licenceId: this.userInfo.licenceId, teacherId: this.userInfo.id,
                    examId: this.examId, examSeq: this.examSeq };
                let param = { controllerName: 'examTask', methodName: '/loadRecentZgStudent',  param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data) {
                        this.tableData = data;

                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },

            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadRecentList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadRecentList()
            },

            openRecent(row){
                this.$emit('ok', row);
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .search_container{

    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }

</style>


