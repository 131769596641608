<template>
    <el-container class="scoring" :style="scoringStyle">
        <el-header>
            <el-row>
                <el-col :span="2" style="text-align: left; ">
                    <i class="el-icon-s-fold icon" @click="setMenuCollapse"></i>
                    <i class="el-icon-search icon" style="margin-left: 20px;" @click="openSearchDialog"></i>
                </el-col>
                <el-col :span="8" style="text-align: left;">
<!--                    <el-tooltip class="item" effect="dark" :content="examInfo.name" placement="bottom">-->
                        <div>
                            <span class="head" v-if="examInfo.name!=null" >{{showExamName(examInfo.name)+"("+examInfo.id+")"}}，</span>
                            <span class="head">参考人数：{{examInfo.joinCount||0}}, 还需阅：{{totalCount}}, 已阅：{{yyCount}}</span>
                        </div>
<!--                    </el-tooltip>-->
                </el-col>
                <el-col :span="2">
                  <el-select v-model="yyOrderBy" placeholder="请选择" @change="reload">
                    <el-option v-for="item in yyOrderOptions" :key="item.key" :label="item.name" :value="item.key"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="2" style="padding: 0px 12px;">
                  <el-checkbox v-model="showAllChecked" v-if="userInfo.id==examInfo.createrId" @change="showAllChange">显示所有老师已阅记录</el-checkbox>
                </el-col>
                <el-col :span="10" style="text-align: right; ">
                    <span class="head">{{(selZgInfo!=null && selZgInfo.abPaper)?(selZgInfo.abPaper+"卷，"):""}}{{(baseSetting.showStudentInfo && selZgInfo!=null)?("学号："+selZgInfo.studentNo):""}}</span>
                    <span class="head" v-if="baseSetting.showStudentInfo && selZgInfo!=null">，姓名：{{selZgInfo.studentName}}，</span>
                    <span class="head" v-if="baseSetting.showKg && selZgInfo!=null">客观分数：{{selZgInfo.kgScore}}</span>
<!--                    <i class="head el-icon-time icon"  @click="isDrawer=true"></i>-->
                </el-col>

            </el-row>

        </el-header>

        <el-container>
            <el-aside :width="asideWidth" style="background-color: rgb(238, 241, 246);overflow-x: hidden;">

                <el-menu :collapse="isCollapse" :default-active="activeIndex" :default-openeds="defaultActive" :style="{ height: menuHeight+'px'}">
                    <template v-for="(item,index) in menuData">
                        <!--表示 有一级菜单-->
                        <div v-if="!item.children || item.children.length == 0">
                            <el-menu-item class="dropItem" :index="item.key" @click="menuClick(item)">
                                <i v-if="item.icon" :class="item.icon"></i>
                                <span v-if="item.name" slot="title">{{ item.name}}</span>
                            </el-menu-item>

                        </div>

                        <!--表示 有二级或者多级菜单 -->
                        <el-submenu v-if="item.children && item.children.length >= 0" :index="item.key"  class="dropItem">
                            <template slot="title">
                                <i v-if="item.icon" :class="item.icon"></i>
                                <span v-if="item.name" slot="title">{{item.name}}</span>
                            </template>
                            <!--直接定位到子路由上，子路由也可以实现导航功能-->
                            <div v-for="(citem, cindex) in item.children">
                                <el-menu-item v-if="!citem.children || citem.children.length == 0" :index="citem.key" @click="menuClick(citem)">
                                    <span slot="title"> {{citem.name}}</span>
                                </el-menu-item>

                                <el-submenu v-if="citem.children && citem.children.length >= 0" :index="citem.key"  class="dropItem">
                                    <template slot="title">
                                        <i v-if="citem.icon" :class="citem.icon"></i>
                                        <span v-if="citem.name" slot="title">{{citem.name}}</span>
                                    </template>
                                    <div v-for="(subItem, subIndex) in citem.children">
                                        <el-menu-item :index="subItem.key" @click="menuClick(subItem, subIndex, citem.children)">
                                            <span slot="title" :style="{color: subItem.isModify?'red':'', background: subItem.first? 'rgb(103, 194, 58)':'', padding: '10px'}">
                                              {{subItem.name}}
                                            </span>
                                        </el-menu-item>

                                    </div>
                                </el-submenu>
                            </div>
                        </el-submenu>
                    </template>
                </el-menu>


            </el-aside>
            <el-main :style="mainStyle" v-loading="loading">
                <div style="float: left; padding-top: 40px;" id="canvasDiv">
                    <canvas id="myCanvas1"  :width="canvasWidth" :height="canvasHeight" @click.stop="canvasClick">
                    </canvas>
                    <scorePoint :w="item.w" :h="item.h" :x="item.showX" :y="item.showY" :id="item.index" :arrIdx="index" v-for="(item, index) in drawList"
                           :type="item.type" :other="item" :tiScore="item.score" :eColor="item.color" :isDraggable="item.isD" :isResizable="item.isR"
                            @changeScore="changeScore" @select="selectPoint" @repos="changePoint" @quickScore="quickScoreSet" @deleteMe="spDeleteMe"></scorePoint>

                </div>

                <rightPanel ref="rightPanel" :zgInfo="selZgInfo" :visible="selZgInfo!=null" :showAllExam="baseSetting.showAllExam" :isEdit="isEdit" @quickScore="quickScoreAction"
                            @changeMode="rpChangeMode" @okExam="openViewer" @submitScore="rpSubmitScore" @submitNext="submitNext" @reEdit="reEdit"></rightPanel>
                <operPanel ref="operPanel" :status="selZgInfo.status" :x="operInfo.x" :y="operInfo.y" :visible="selZgInfo!=null" v-if="selZgInfo!=null" @ok="operOK" ></operPanel>
            </el-main>
        </el-container>

        <el-image-viewer v-if="showViewer && examImageList" :on-close="closeViewer" :url-list="examImageList" />
        <searchDialog ref="searchDialog" @ok="searchStudentOK"></searchDialog>
        <el-drawer title="已阅列表" :visible.sync="isDrawer" :modal="false" direction="rtl" :before-close="handleDrawerClose">
            <recentList ref="recentList" :examId="examId" :examSeq="zgForm.examSeq" :show="isDrawer" @ok="recentClick"></recentList>
        </el-drawer>
    </el-container>

</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import * as simpleApi from "request/simple";
    import rightPanel from "./rightPanel";
    import operPanel from "./operPanel";
    import scorePoint from "./scorePoint";
    import searchDialog from "./searchDialog";
    import recentList from "./recentList";
    import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
    import XEUtils from "xe-utils";
    import {splitTime, suoXiao} from "../../../utils/mUtils";
    import key from 'keymaster'

    export default {
        name: 'scanPre',
        data(){
            return {
                examId: null, loading: false, isDrawer: false, showAllChecked: false, scoringStyle: {}, asideWidth: "200px", mainStyle: {},
                formStyle: {}, canvasWidth: 1000, canvasHeight: 1000, showViewer: false, menuHeight: 700,
                yyOrderBy: 'zg.uddt desc', yyOrderOptions: [{key: "zg.student_no", name: "按学号排序"}, {key: "zg.uddt desc", name: "按时间倒序"}],
                myCanvas: null, ctx: null, imgScale: 0.5, imgList: [], examImageList: [], isEdit: false,
                rules: null, isCollapse: false, selZgInfo: null, selPoint: null, selPointIndex: -1, scanPreResult: null, selMenu: null,
                baseSetting: { showStudentInfo: false, showAllExam: false, showKg: false},
                zgForm: {examId: null, teacherId: null, examSeq: null, idxCount: 1, nextExamSeq: null, studentId: null,
                    subIndex: 0, totalCount: 0, status: null, inStatus: null, hardClassId: null, limit: null},
                defaultActive: ["root"],  examInfo: {}, totalCount: 0, yyCount: 0,
                menuData:[], searchMenu: null, searchIxList: [], zgSeqList: [], zgYYSeqList: [], hardSeqList: [], teachingSeqList: [], zgData: [],
                activeIndex: null, hotIndex: -1, hotList: [], //快捷键列表
                //快捷打分，填空题
                isQuickScore: false, topicData: [], fillBlankList: [], quickExamSeq: null,
                drawMode: -1, drawList: [], caiDian: {topicIndex: null, myScore: null, step: 1},
                operInfo: {x: 250, y: 500},
            }
        },
        components:{
            ElImageViewer, rightPanel, operPanel, scorePoint, searchDialog, recentList
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
        created() {
            let myHeight = document.documentElement.clientHeight ;
            let myWidth = document.documentElement.clientWidth ;
            this.formStyle = {height: myHeight, width: myWidth};
            console.log('myHeight:', myHeight);
            this.scoringStyle = {height: (myHeight) + 'px', border: "1px solid #eee"};
            this.mainStyle = {width: (myWidth-200) + 'px'}
            this.canvasWidth = myWidth -600;
            this.canvasHeight = myHeight - 450;

        },
      	mounted() {
            let examId = this.$route.query.e;
            console.log("examId", examId);
            if(examId && examId != null){
                this.examId = parseInt(examId);
                this.zgForm.examId = parseInt(examId);
                this.zgForm.teacherId = this.userInfo.id;
            }
            let that = this;
            this.$nextTick(() => {
                that.menuHeight = document.documentElement.clientHeight-80;
                window.addEventListener("scroll", that.handleScroll, true);
            });
          key('s', ()=>{ this.keyS()});
          key('w', ()=>{ this.keyW()});
	    },
        watch: {
            examId: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.reload();
                    }
                }
            },
            drawMode: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log('drawMode change', n);
                    let that = this;
                    if(n ==8){ //重置所有数据

                        this.examImageList = [];
                        this.selPoint = null;
                        if(this.scanPreResult != null){
                            if(this.scanPreResult.oss_full_name1 != null)this.examImageList.push(this.scanPreResult.oss_full_name1);
                            if(this.scanPreResult.oss_full_name2 != null) this.examImageList.push(this.scanPreResult.oss_full_name2);
                        }
                        if(this.isQuickScore){
                          for (let i=this.drawList.length-1; i>=0; i--){
                            let w = this.drawList[i];
                            if(w.type == 100) w.score = 0;
                            else this.drawList.splice(i, 1)
                          }
                        }else{
                          this.drawList = [];
                        }
                        this.$refs.rightPanel.clearScore();
                        this.drawMode = -1;
                    }
                    if(n == 9 || n == 10){
                        this.examImageList = [];
                        this.drawList = [];
                        if(this.zgForm.limit == null) {
                            this.zgForm.limit = 0;
                        } else{
                            console.log(this.zgForm.limit, this.zgForm.idxCount)
                            if(n == 10) this.zgForm.limit = this.zgForm.limit + this.zgForm.idxCount;
                            if(n == 9 && this.zgForm.limit > 0) this.zgForm.limit = this.zgForm.limit - this.zgForm.idxCount;
                        }
                        this.loadZgExam();
                        this.drawMode = -1;
                    }
                    if(n ==5){ //删除标记
                        let that = this;
                        if(this.selPointIndex>-1){
                            let selPoint = this.drawList[this.selPointIndex];
                            console.log('del', this.selPointIndex, selPoint.showX, selPoint.showY);
                            if(selPoint.type == 99){
                                console.log('del selPoint', selPoint);
                                this.$refs.rightPanel.caidianScore(selPoint.topicIndex, 0-selPoint.score, false, true);
                            }
                            if(selPoint.type == 100){selPoint.score = 0; this.$refs.rightPanel.caidianScore(selPoint.topicIndex, 0, true, true); this.drawMode = -1; return;}
                            this.loading = true;
                            let myList = Object.assign(this.drawList);
                            myList.splice(this.selPointIndex, 1);
                            this.drawList = [];
                            setTimeout(function () {
                                that.drawList = myList;
                                // that.drawList.forEach((item, index) => {console.log(index, item.showX, item.showY)});
                              that.loading = false;
                            }, 800);
                        }
                        this.drawMode = -1;
                    }
                    if(n > 0 && "rightPanel" in this.$refs && this.$refs.rightPanel != null){
                        if(n != 99) this.$refs.rightPanel.makeTopicValue(null);
                    }
                }
            },
            isCollapse: {
                deep: true, immediate: true,
                handler(n, o) {
                    let myHeight = document.documentElement.clientHeight ;
                    let myWidth = document.documentElement.clientWidth ;
                    if(!this.isCollapse){
                        this.asideWidth = "200px";
                        this.mainStyle.width = (this.formStyle.width - 200) + "px";
                        this.canvasWidth = myWidth -600;
                    }else{
                        this.asideWidth = "50px";
                        this.mainStyle.width = (this.formStyle.width - 50) + "px";
                        this.canvasWidth = myWidth -450;
                    }
                    this.drawList = [];
                    this.loadCanvas();
                    this.$set(this, "mainStyle", this.mainStyle);
                }
            },
        },
        methods: {
          keyS(){
            if(this.isEdit && this.hotList != null && this.hotList.length > 0){
              this.hotIndex++;
              if(this.hotIndex >= this.hotList.length) this.hotIndex = 0
              this.menuClick(this.hotList[this.hotIndex], this.hotIndex, this.hotList)
              this.activeIndex = this.hotList[this.hotIndex].key;
            }
          },
          keyW(){
            if(this.isEdit && this.hotList != null && this.hotList.length > 0){
              this.hotIndex--;
              this.menuClick(this.hotList[this.hotIndex], this.hotIndex, this.hotList)
              this.activeIndex = this.hotList[this.hotIndex].key;
            }
          },
            handleScroll(e) {
                if(("documentElement" in e.target) && ("scrollTop" in e.target.documentElement)){
                    let scrollTop = e.target.documentElement.scrollTop;
                    this.operInfo.y = scrollTop + 600;
                }
            },
            showAllChange(v){
              console.log("showAllChange", v)
              this.reload();
            },
            reload(){
                let that = this;
                this.loading = false;
                this.drawList = [];
                this.loadExam(function () {
                    that.loadExamTopic(function () {
                        that.loadMenu(function () {
                            that.zgForm.inStatus = ['01', '02'];
                            if(that.totalCount == 0){
                                that.$alert('您的阅卷已全部阅完', '提示');
                            }
                          that.loading = false;
                        });
                    });
                });
            },
            handleDrawerClose(done){
                done();
            },
            loadExamTopic(callback){
                let search = { teacherId: this.userInfo.id, examId: parseInt(this.examId), showAll: this.showAllChecked?1:0, yyOrderBy: this.yyOrderBy};
                if(this.rules != null && this.rules.hardClassList != null && this.rules.hardClassList.length > 0 ){
                    let hardClassList = [];
                    for (let i=0; i<this.rules.hardClassList.length; i++){
                        let d = this.rules.hardClassList[i];
                        hardClassList.push({id: d.hardClassId});
                    }
                    search["hardClassList"] = hardClassList;
                }
                if(this.rules != null && this.rules.teachingClassList != null && this.rules.teachingClassList.length > 0 ){
                    let teachingClassList = [];
                    for (let i=0; i<this.rules.teachingClassList.length; i++){
                        let d = this.rules.teachingClassList[i];
                        teachingClassList.push({id: d.id});
                    }
                    search["teachingClassList"] = teachingClassList;
                }

                let param = { controllerName: 'examTask', methodName: '/teacherTask',  param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadExamTopic", code, data);
                    if (code == 0) {
                        this.zgSeqList = data.zgSeqList;
                        this.zgYYSeqList = data.zgYYSeqList;
                        if(data.zgCountMap){
                            this.totalCount = data.zgCountMap.w_cou;
                            this.yyCount = data.zgCountMap.yy_cou;
                        }
                        this.hardSeqList = [];
                        if(this.rules != null && this.rules.hardClassList != null && this.rules.hardClassList.length > 0 ){
                            let hardClassList = [];
                            for (let i=0; i<this.rules.hardClassList.length; i++){
                                let d = this.rules.hardClassList[i];
                                let hard = data[d.hardClassId];
                                if(hard){
                                    hardClassList.push({id: d.hardClassId, name: d.hardName, list: hard});
                                }
                            }
                            this.hardSeqList = hardClassList;
                        }
                        this.teachingSeqList = []
                        if(this.rules != null && this.rules.teachingClassList != null && this.rules.teachingClassList.length > 0 ){
                            let teachingSeqList = [];
                            for (let i=0; i<this.rules.teachingClassList.length; i++){
                                let d = this.rules.teachingClassList[i];
                                let hard = data[d.id];
                                if(hard){
                                    teachingSeqList.push({id: d.id, name: d.name, list: hard});
                                }
                            }
                            this.teachingSeqList = teachingSeqList;
                        }
                        if(callback) callback();

                    }

                }).catch((error) => {
                    console.log("error", error)
                });
            },
            loadExam(callback){
                let param = { controllerName: 'akExam', methodName: '/get',  param: {id: parseInt(this.examId)}};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadExam", code, data);
                    if (code == 0) {
                        this.examInfo = data;
                        if(data && data.rules != null){
                            this.rules = JSON.parse(data.rules);
                            this.baseSetting = this.rules.baseSetting;
                        }
                    }
                    if(callback) callback();
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadMenu(callback){
                this.menuData = [];
                let children = [];
                for (let i=0; i<this.zgSeqList.length; i++){
                    let d = this.zgSeqList[i];
                    if(d.yy_cou == d.cou) continue;
                    let examSeq = d.exam_seq;
                    let ixFind = XEUtils.find(this.searchIxList, item => item.exam_seq==d.exam_seq);
                    if(!ixFind) this.searchIxList.push({exam_seq: d.exam_seq})
                    if(examSeq.length > 7){
                        examSeq = examSeq.substr(0,2) + "..." + examSeq.substr(examSeq.length-2,examSeq.length-1);
                    }
                    let idxCount = Math.ceil(d.all_cou / d.cou);
                    // console.log("d", d.exam_seq, d.all_cou, d.cou, idxCount)
                    examSeq = examSeq + "(" + d.yy_cou + "/" + d.cou +")";
                    let nextExamSeq = ((i+1)<this.zgSeqList.length)?this.zgSeqList[i+1].exam_seq: null;
                    children.push({ name: examSeq, icon: "el-icon-user", key: 'zg-'+i, data: {examId: this.examId, examSeq: d.exam_seq, idxCount: idxCount, nextExamSeq: nextExamSeq, isEdit: false, inStatus: ['01', '02']}})
                }
                if(children.length > 0){
                    children = children.sort((a, b) => {
                      let seqA = a.data.examSeq, seqB = b.data.examSeq;
                      let arrA = seqA.split(","), arrB = seqB.split(",");
                      let vA = 0, vB = 0;
                      if(arrA.length > 1) vA = parseInt(arrA[0]); else vA = parseInt(seqA);
                      if(arrB.length > 1) vB = parseInt(arrB[0]); else vB = parseInt(seqB);
                      return vA - vB;
                    })
                    this.zgForm.totalCount = children.length;
                    let zgSeq = { name: "阅卷任务", icon: "el-icon-location", key: 'root', children: children};
                    this.menuData.push(zgSeq);
                }
                if(this.hardSeqList && this.hardSeqList.length > 0){
                    for (let i=0; i<this.hardSeqList.length; i++){
                        let d = this.hardSeqList[i];
                        let hcChildren = [];
                        for (let j=0; j<d.list.length; j++){
                            let h = d.list[j];
                            let examSeq = h.exam_seq;
                            if(examSeq.length > 7){
                                examSeq = examSeq.substr(0,2) + "..." + examSeq.substr(examSeq.length-2,examSeq.length-1);
                            }
                            examSeq = examSeq + "(" + h.yy_cou + "/" + h.cou +")";
                            let idxCount = parseInt(h.all_cou / h.cou);
                            hcChildren.push({ name: examSeq, icon: "el-icon-user",  key: 'hc-'+i + "-" + j, data: {examId: this.examId, examSeq: h.exam_seq, idxCount: idxCount, isEdit: true,  hardClassId: d.id} })
                        }
                        let hardName = d.name;
                        if(hardName.length > 7){
                            hardName = hardName.substr(0,3) + "..." + hardName.substr(hardName.length-4,hardName.length-1);
                        }
                        let hc = { name: hardName, icon: "el-icon-menu",  key: 'hc-'+i, children: hcChildren}
                        if(hcChildren.length > 0)
                            this.menuData.push(hc);
                    }
                }
                if(this.teachingSeqList && this.teachingSeqList.length > 0){
                    for (let i=0; i<this.teachingSeqList.length; i++){
                        let d = this.teachingSeqList[i];
                        let hcChildren = [];
                        for (let j=0; j<d.list.length; j++){
                            let h = d.list[j];
                            let examSeq = h.exam_seq;
                            if(examSeq.length > 7){
                                examSeq = examSeq.substr(0,2) + "..." + examSeq.substr(examSeq.length-2,examSeq.length-1);
                            }
                            examSeq = examSeq + "(" + h.yy_cou + "/" + h.cou +")";
                            let idxCount = parseInt(h.all_cou / h.cou);
                            hcChildren.push({ name: examSeq, icon: "el-icon-user",  key: 'tc-'+i + "-" + j, data: {examId: this.examId, examSeq: h.exam_seq, idxCount: idxCount, isEdit: true, teachingClassId: d.id} })
                        }
                        let hardName = d.name;
                        if(hardName.length > 7){
                            hardName = hardName.substr(0,3) + "..." + hardName.substr(hardName.length-4,hardName.length-1);
                        }
                        let hc = { name: hardName, icon: "el-icon-menu",  key: 'tc-'+i, children: hcChildren}
                        if(hcChildren.length > 0)
                            this.menuData.push(hc);
                    }
                }
                if(this.zgYYSeqList != null && this.zgYYSeqList.length > 0){
                    let children = [];
                    for (let i=0; i<this.zgYYSeqList.length; i++){
                        let d = this.zgYYSeqList[i];
                        let examSeq = d.exam_seq;
                        if(examSeq.length > 7){
                            examSeq = examSeq.substr(0,2) + "..." + examSeq.substr(examSeq.length-2,examSeq.length-1);
                        }
                        let ixFind = XEUtils.find(this.searchIxList, item => item.exam_seq==d.exam_seq);
                        if(!ixFind) this.searchIxList.push({exam_seq: d.exam_seq})
                        let idxCount = parseInt(d.all_cou / d.cou);
                        let zgStuList = d.zgList;
                        if(zgStuList != null && zgStuList.length > 0){
                            examSeq = "题"+examSeq + "(" + zgStuList.length +"条)";
                            let nextExamSeq = ((i+1)<this.zgYYSeqList.length)?this.zgYYSeqList[i+1].exam_seq: null;
                            let pchild = { name: examSeq, icon: "el-icon-user", key: 'zgyy-'+i,
                                data: {examId: this.examId, examSeq: d.exam_seq, idxCount: idxCount, nextExamSeq: nextExamSeq, status: 'YY', isEdit: true, inStatus: null, children: null}};
                            let stuChildren = [];
                            let zgStuLen = zgStuList.length;
                            for (let j=0; j<zgStuLen; j++){
                                let zgStu = zgStuList[j];
                                let stuChildName = (this.baseSetting.showStudentInfo)?(zgStu.student_name+"("+zgStu.student_no+")"):"学生"+(zgStuLen-j);
                                let item = { name: stuChildName,  key: 'zgyy-'+i+zgStu.student_id, isModify: (zgStu.power1>0||zgStu.power2>0),
                                    data: {examId: this.examId, examSeq: d.exam_seq, studentId: zgStu.student_id, status: 'YY', isEdit: true, inStatus: null}
                                }
                                if (j===0){
                                  item['first'] = true
                                }
                                stuChildren.push(
                                    item
                                    )
                            }
                            pchild.children = stuChildren;
                            children.push(pchild);
                        }

                    }
                    if(children.length > 0){
                        children = children.sort((a, b) => {
                          let seqA = a.data.examSeq, seqB = b.data.examSeq;
                          let arrA = seqA.split(","), arrB = seqB.split(",");
                          let vA = 0, vB = 0;
                          if(arrA.length > 1) vA = parseInt(arrA[0]); else vA = parseInt(seqA);
                          if(arrB.length > 1) vB = parseInt(arrB[0]); else vB = parseInt(seqB);
                          return vA - vB;
                        })
                        let zgSeq = { name: "已阅任务", icon: "el-icon-edit", key: 'yyRoot', children: children};
                        this.menuData.push(zgSeq);
                    }
                }
                if(this.searchMenu != null){
                    this.menuData.push(this.searchMenu);
                }
                if(callback) callback();
            },
            searchStudentOK(s){
                this.loading = true;
                let that = this;
                let param = { controllerName: 'examTask', methodName: '/studentByTotalScore',
                    param: {examId: parseInt(this.examId), yy: 'no', studentName: s.studentName, studentNo: s.studentNo, teacherId: this.userInfo.id, examSeq: s.examSeq,
                        licenceId: this.userInfo.licenceId, fromScore: XEUtils.toNumber(s.scoreFrom), toScore: XEUtils.toNumber(s.scoreTo)}};
                this.searchMenu = null;
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("searchStudentOK", code, data);
                    if (code == 0 && data && data.length > 0) {
                        let m = {name: "搜索结果", icon: "el-icon-search", key: 'searchRoot', children: []}
                        let studentList = [];
                        for (let i=0; i<data.length; i++){
                            let d = data[i];
                            let children = [];
                            if(d.examSeqList && d.examSeqList.length > 0){
                                for (let j=0; j<d.examSeqList.length; j++){
                                    let e = d.examSeqList[j];
                                    let examSeq = e.exam_seq;
                                    if(examSeq.length > 7) examSeq = examSeq.substr(0,2) + "..." + examSeq.substr(examSeq.length-2,examSeq.length-1);
                                    examSeq = examSeq + "(" + e.cou +"块)";
                                    children.push({ name: examSeq, icon: "el-icon-user", key: 'stuyy-'+j,
                                        data: {examId: this.examId, examSeq: e.exam_seq, idxCount: e.idx_cou, studentId: d.student_id, isEdit: true, inStatus: null}})
                                }
                            }
                            if(children.length > 0) studentList.push({ name: d.student_name+"(" + d.total_score + "分)", icon: "el-icon-user", key: 'stu-'+i, children: children});
                        }
                        if(studentList.length > 0){
                          m.children = studentList;
                          this.searchMenu = m;
                          console.log("searchStudentOK", m);
                          let mfIndex = XEUtils.findIndexOf(this.menuData, item => { return item.key == 'searchRoot'; });
                          if(mfIndex > -1) {
                              this.menuData.splice(mfIndex, 1);
                              setTimeout(function () {
                                  that.menuData.push(m);
                              }, 300);
                          } else {
                              this.menuData.push(m);
                          }
                        }

                    }
                    this.loading = false;
                }).catch((error) => {
                    console.log("error", error);
                    this.loading = false;
                });
            },
            setMenuCollapse(){
                this.isCollapse = !this.isCollapse;
            },
            showExamName(examName){
                if(examName.length > 12)
                    return  examName.substr(0,6) + "..." + examName.substr(examName.length-6,examName.length-1);
                return examName;
            },
            menuClick(item, subIndex, hotList){
                console.log("menuClick", item);
                this.selMenu = item;
                this.examImageList = [];
                this.drawList = [];
                this.zgForm.examSeq = item.data.examSeq;
                if(this.quickExamSeq != item.data.examSeq) this.fillBlankList = [] //如果不是同个主观题，则清除原先摆好的位置
                this.quickExamSeq = item.data.examSeq
                if(("idxCount" in item.data)) this.zgForm.idxCount = item.data.idxCount; else this.zgForm.idxCount = 1;
                if("isEdit" in item.data) this.isEdit = item.data.isEdit; else this.isEdit = false;
                if(this.isEdit){
                  this.hotList = hotList;
                  this.hotIndex = subIndex;
                }
                this.zgForm.nextExamSeq = item.data.nextExamSeq;
                this.zgForm.status = item.data.status;
                this.zgForm.inStatus = item.data.inStatus;
                this.zgForm.subIndex = subIndex;
                this.zgForm.hardClassId = item.data.hardClassId;
                this.zgForm.teachingClassId = item.data.teachingClassId;
                if(("studentId" in item.data)) {
                    this.zgForm.studentId = item.data.studentId;
                    this.zgForm.teacherId = null;
                } else {
                    this.zgForm.studentId = null;
                    this.zgForm.teacherId = this.userInfo.id;
                }
                if(item.data.status == 'YY') this.zgForm.limit = 0; else this.zgForm.limit = null;
                this.loadZgExam();
            },
            recentClick(row){
                console.log("recentClick", row);
                this.examImageList = [];
                this.drawList = [];
                this.zgForm.examSeq = row.exam_seq;
                this.zgForm.studentId = row.student_id;
                this.zgForm.idxCount = row.idxCount;
                this.zgForm.status = "YY";
                this.zgForm.inStatus = null;
                this.zgForm.teacherId = this.userInfo.id;
                this.zgForm.limit = null;
                this.loadZgExam();
            },
            loadZgExam(){
                this.loading = true;
                let param = { controllerName: 'examTask', methodName: '/getZgList',  param: this.zgForm};
                this.zgData = [];
                let that = this;
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadExam", code, data);
                    if (code == 0 && data && data.length > 0) {

                        let first = data[0];
                        this.selZgInfo = {examId: first.exam_id, examSeq: first.exam_seq, studentId: first.student_id, scanFileId: first.scan_file_id,
                            studentNo: first.student_no, studentName: first.student_name, kgScore: first.kg_score, zgIds: [first.id], teacherId: first.teacher_id, isQuickScore: first.isQuickScore,
                            markRecords: [first.mark_record], drawImgs: [first.draw_img], status: first.status, abPaper: first.ab_paper }
                        if(data.length == 1) {
                          this.zgData = data;
                        }else{
                          this.zgData = [];
                          this.selZgInfo.zgIds = [];
                            this.selZgInfo.markRecords = [];
                            this.selZgInfo.drawImgs = [];
                          for (let i=0; i<data.length; i++){
                            let ed = data[i];
                            if(first.student_id == ed.student_id){
                              this.zgData.push(ed);
                              this.selZgInfo.zgIds.push(ed.id);
                              this.selZgInfo.markRecords.push(ed.mark_record);
                              this.selZgInfo.drawImgs.push(ed.draw_img);
                            }
                          }
                        }
                        this.loadCanvas();
                        this.loadFullExam();
                    }else{
                        if(code == 11){
                            this.$alert('您的阅卷已全部阅完', '提示');
                        }else if(code == 12){
                            this.$alert('题块:'+this.zgForm.examSeq+'，已到最后一题', '提示');
                            this.zgForm.limit = 0;
                        }else if(code == 13){
                            this.$confirm('本题批阅任务已完成，是否批阅剩余阅卷任务？', '提示', {
                                confirmButtonText: '是', cancelButtonText: '否', type: 'warning'
                            }).then(() => {
                                if(that.menuData[0].key == 'root'){
                                    let subChildren = that.menuData[0].children;
                                    this.zgForm.subIndex = this.zgForm.subIndex + 1;
                                    if(this.zgForm.subIndex > this.zgForm.totalCount) this.zgForm.subIndex = 0;
                                    let mySub = subChildren[this.zgForm.subIndex];
                                    that.menuClick(mySub, this.zgForm.subIndex);
                                }

                            }).catch(() => {

                            });
                        }
                        this.examImageList = [];
                        this.drawList = [];
                        this.selZgInfo = null;
                        this.clearCanvas();
                        this.loading = false;
                    }

                }).catch((error) => {
                    console.log("error", error);
                    this.loading = false;
                });
            },
            loadFullExam(){
                if(this.baseSetting.showAllExam){
                    let that = this;
                    let search = { licenceId: this.userInfo.licenceId, examId: this.selZgInfo.examId, studentId: this.selZgInfo.studentId};
                    let param = { controllerName: 'scan/scanPreResult', methodName: '/list',  param: search};
                    return simpleApi.list(param).then(({result: {code, data}}) =>  {
                        if (data && data.result && data.result.length > 0) {
                            console.log("scanPreResult", data);
                            let scanPreResult = data.result[0];
                            this.scanPreResult = scanPreResult;
                            if(scanPreResult.oss_full_name1 != null){
                                this.examImageList.push(scanPreResult.oss_full_name1);
                            }
                            if(scanPreResult.oss_full_name2 != null){
                                this.examImageList.push(scanPreResult.oss_full_name2);
                            }
                        }
                    }).catch((error) => {
                        console.log("error", error)
                    });
                }
            },
            submitNext(){
                this.examImageList = [];
                this.loadZgExam();
                this.drawMode = -1;
            },
            reEdit(){
                for (let i=0; i<this.drawList.length; i++){
                  let d = this.drawList[i]
                  d["power"] = 0;
                  d["color"] = 'red';
                }
            },
            quickScoreAction(o){
              let {show, topicType, topicData, reset} = o;
              this.isQuickScore = show;
              this.topicType = topicType
              this.topicData = []
              let that = this;
              if(!this.myCanvas) return;
              let rect = this.myCanvas.getBoundingClientRect();
              let canLeft = rect.left, canTop = rect.top, canWidth = rect.width, canHeight = rect.height;
              if(reset){ //复原位置
                this.drawList = []
                for (let i=0; i<topicData.length; i++) {
                  let t = topicData[i]
                  let x = canLeft + (i%3)*120 + 10, y = canTop + parseInt(i/3) * 40+5;
                  // let ixIdx = XEUtils.findIndexOf(this.drawList, item => (item.type == 100 && item.topicIndex==t.topicIndex));
                  let o = { index: this.drawList.length, w: 80, h: 40, x: x, y: y, scaleX: x, scaleY: y, topicIndex: t.topicIndex,
                    showX: x, showY: y, type: 100, score: 0, scoreStep: 0.5, rightScore: t.rightScore, isR: true, isD: true, move: true};
                  // if(ixIdx > -1){
                  //   o.score = this.drawList[ixIdx].score;
                  //   this.drawList.splice(ixIdx, 1)
                  // }
                  setTimeout(()=>{
                    that.drawList.push(o)
                  }, 500);
                }
              }else{
                for (let i=this.drawList.length-1; i>=0; i--){
                  if(this.drawList[i].type == 100) this.drawList.splice(i, 1)
                }if(!show){
                  // if("rightPanel" in this.$refs) this.$refs.rightPanel.clearScore();
                }else if(topicType=='FillBlank'){
                  this.topicData = topicData;
                  for (let i=0; i<topicData.length; i++){
                    let t = topicData[i]
                    let ixFind = XEUtils.find(this.fillBlankList, item => item.topicIndex==t.topicIndex);
                    // console.log('quickA', t.topicIndex, this.fillBlankList);
                    if(ixFind){
                      this.drawList.push(ixFind)
                    }else{
                      let x = canLeft + (i%3)*120 + 10, y = canTop + parseInt(i/3) * 40+5;
                      let o = { index: this.drawList.length, w: 80, h: 40, x: x, y: y, scaleX: x, scaleY: y, topicIndex: t.topicIndex,
                        showX: x, showY: y, type: 100, score: 0, scoreStep: 0.5, rightScore: t.rightScore, isR: true, isD: true, move: true};
                      // console.log('quickScoreAction', x, y, o);
                      this.drawList.push(o)
                    }
                  }
                }
              }
            },
            quickScoreSet(id, arrIdx){
              let selPoint = this.drawList[arrIdx];
              this.selPointIndex = arrIdx;
              if(this.drawMode == 99 && this.caiDian && XEUtils.isNumber(this.caiDian.myScore)){
                let myScore = parseFloat(this.caiDian.myScore)
                selPoint["score"] = myScore;
                let calcScore = myScore
                if(myScore<0) calcScore = this.caiDian.rightScore + myScore;
                this.$refs.rightPanel.caidianScore(selPoint.topicIndex, calcScore, true);
              }
            },
          spDeleteMe(id, arrIdx){
            console.log("spDeleteMe", id, arrIdx)
            this.selPointIndex = arrIdx;
            this.drawMode = 5

          },
            loadCanvas(){
                let that = this;
                this.imgList = [];
                this.$nextTick(() => {
                    if(that.zgData == null || that.zgData.length < 1) return ;
                    let canvas = document.getElementById("myCanvas1");
                    console.log("loadCanvas ", that.zgData, that.canvasWidth, that.canvasHeight)
                    if(canvas.getContext){
                        this.myCanvas = canvas;
                        let ctx = canvas.getContext('2d');
                        this.ctx = ctx;
                        let myCount = 0;
                        for (let i=0; i<that.zgData.length; i++){
                            let ed = that.zgData[i];
                            let img = new Image();
                            console.log("ed ", i, ed)
                            img.src = mUtils.urlAddTimestamp(window.g.OSS_HOST + ed.oss_name);
                            that.examImageList.push(img.src);
                            that.imgList.push({img: img, width: 0, height: 0});
                            img.onload = function () {
                                that.imgList[i].width = this.width;
                                that.imgList[i].height = this.height;
                                // that.drawImage(i);
                                if(that.zgData.length == (myCount+1)) that.drawImage(i); else myCount = myCount + 1;
                              }
                        }
                    }
                });
            },
            clearCanvas(){
                let w = this.myCanvas.getBoundingClientRect().width;
                let h = this.myCanvas.getBoundingClientRect().height;
                let ctx = this.myCanvas.getContext('2d');
                ctx.clearRect(0, 0, w, h);
            },
            drawImage(imgIndex, setScale){
              let myWidth=0, myHeight=0;
              for (let i=0; i<this.imgList.length; i++){
                let myImg = this.imgList[i];
                myWidth = myImg.width;
                myHeight = myHeight + myImg.height;
                console.log('myHeight', i, myHeight, myImg.height);
              }
              if(!setScale){
                let scale = 1;
                if(myWidth > this.canvasWidth){
                    console.log('myWidth', myHeight, myWidth);
                    scale = this.canvasWidth/myWidth;
                    this.ctx.width = myWidth * scale;
                    this.ctx.height = myHeight * scale;
                    this.imgScale = scale;
                    console.log("scale ", scale)
                }else{
                    this.ctx.width = myWidth;
                    this.ctx.height = myHeight;
                }
                this.myCanvas.width = this.ctx.width;
                this.myCanvas.height = this.ctx.height;
                console.log("drawImage", this.ctx.width, this.ctx.height)
                let dy = 0;
                for (let i=0; i<this.imgList.length; i++){
                  let myImg = this.imgList[i];
                  let imgWidth = myImg.width*scale;
                  let imgHeight = myImg.height*scale;
                  this.ctx.drawImage(myImg.img, 0, dy, imgWidth, imgHeight);
                    console.log("ctxdraw Image", 0, dy, imgWidth, imgHeight)
                  this.selZgInfo["zg"+(i+1)] = {x: 0, y: dy, width: imgWidth, height: imgHeight};
                  dy = dy + imgHeight;
                }
                console.log("selZgInfo", this.selZgInfo)
              }
              let that = this;
              this.operInfo = {x: this.myCanvas.getBoundingClientRect().left+this.myCanvas.getBoundingClientRect().width-80, y: document.documentElement.clientHeight-100};
              setTimeout(function (){
                that.loadMarkRecord(imgIndex,function (){
                  that.loading = false;
                });
              }, 500)

            },
            loadMarkRecord(imgIndex, callback){
              let that = this;
                if(this.selZgInfo.status == 'YY'){
                    let markRecords = this.selZgInfo.markRecords;
                    if(markRecords != null && markRecords.length > 0){
                      let gu = 121;
                      let myHeight = document.documentElement.clientHeight
                      for (let i=0; i<markRecords.length; i++){
                          if(markRecords[i] != null){
                                let mrjson = JSON.parse(markRecords[i]);
                                let zgInfo = this.selZgInfo["zg"+(i+1)];
                                console.log('loadMarkRecord'+i, zgInfo, mrjson);
                            let rect = this.myCanvas.getBoundingClientRect();
                            let canLeft = rect.left, canTop = rect.top, canWidth = rect.width, canHeight = rect.height;
                                  console.log("getBoundingClientRect", canLeft, canTop, canWidth, canHeight);
                                  if(canTop <0) canTop = 120;
                                if("points" in mrjson && mrjson.points.length > 0){

                                  for (let i=0; i<mrjson.points.length; i++){
                                    let m = mrjson.points[i];
                                      let o = null;
                                      let showY = canHeight * suoXiao(m.scaleY) + gu;
                                      let showX = canLeft + canWidth*suoXiao(m.scaleX)
                                      o = Object.assign(m, {showX: showX, showY: showY});
                                        if(m.type == 4 && "wenziText" in m){
                                            let pw =  m.wenziText.length * 20;
                                            o.w = 40+pw;
                                            console.log("wenziText", m.wenziText, o)
                                        }
                                    // console.log(i+1, "oldShowY", showY, "x:", o.x, 'y:', o.y, 'showX:', o.showX, 'showY:', o.showY, 'scaleX:', m.scaleX, m.scaleX1, 'scaleY:', m.scaleY, o);
                                    let isExist = false;
                                    for (let k=0; k<this.drawList.length; k++){
                                      let dl = this.drawList[k]
                                      if(dl.x == o.x && dl.y == o.y){
                                        isExist = true
                                        break;
                                      }
                                    }
                                    if(!isExist) this.drawList.push(o);
                                  }
                                }
                          }
                      }
                    }
                }else{
                  if(this.isQuickScore){
                    if(this.fillBlankList.length > 0){
                      for (let i=0; i<this.fillBlankList.length; i++){
                        this.drawList.push(JSON.parse(JSON.stringify(this.fillBlankList[i])))
                      }
                    }
                  }
                }
                if(callback) callback();
            },
            canvasClick(e){
              let gu = 121;
                if(this.drawMode > 0){
                  let rect = this.myCanvas.getBoundingClientRect();
                    let x = e.clientX, y = e.clientY, cha = 0;
                    if(rect.top>=0 && rect.top <=gu){
                      cha = parseInt(gu-rect.top);
                    }
                    if(rect.top <0){
                      cha = 0 - parseInt(rect.top-gu)
                    }
                    y = y + cha;
                    let beginHeight =rect.top, endHeight = (rect.top>0)?(rect.top+rect.height):(rect.height-rect.top);
                    // if(rect.top > 0) {y = y + scrollTop; showY = showY + scrollTop; }
                    let scaleX = (x-rect.left) / rect.width;
                    let scaleY = (y-gu) / rect.height;
                    console.log("canvasClick", e.clientX,  e.clientY, x, y, rect, cha, scaleX.toFixed(2), scaleY.toFixed(2));
                    if(y > beginHeight && y < endHeight){
                        let o = { index: this.drawList.length, w: 40, h: 40, x: x, y: y, scaleX: scaleX, scaleY: scaleY,
                            showX: x, showY: y, type: this.drawMode, isR: false, isD: true, move: true};
                      if(this.isEdit){
                        if(this.userInfo.id == this.selZgInfo.teacherId){
                          o["power"] = 1;
                          o["color"] = 'darkred';
                        }else{
                          o["power"] = 2;
                          o["color"] = 'indianred';
                        }

                      }
                        if(this.drawMode == 3 || this.drawMode == 4 || this.drawMode == 14) {
                            o.w = 80; o.isR = true;
                        }
                        if(this.drawMode == 12) {
                            o.h = 5; o.w = 100; o.isR = true;
                        }
                        if(this.drawMode == 99) { //踩点打分
                            if((!XEUtils.isNumber(this.caiDian.myScore)) || this.isQuickScore) return;
                            o["score"] = this.caiDian.myScore;
                            o["topicIndex"] = this.caiDian.topicIndex;
                            o["scoreStep"] = this.caiDian.step;
                            let isSuccess = this.$refs.rightPanel.caidianScore(this.caiDian.topicIndex, this.caiDian.myScore);
                            if(!isSuccess){
                                this.$message({message: '点击分数不能超过满分或小于0分！', type: 'error', duration: 2000});
                                return;
                            }
                        }
                        this.drawList.push(o);
                        if(this.drawMode == 4) {
                            this.drawMode = -1;
                            this.$refs.operPanel.unSelAll();
                        }
                        console.log("canvasClick", o);
                    }else{
                      console.log("not in", e.clientX,  e.clientY, x, y, rect, scaleX, scaleY);
                    }
                }
              this.selPointIndex = -1
            },
            selectPoint(id, arrIdx){
                this.selPoint = this.drawList[arrIdx];
                this.selPointIndex = arrIdx;
                console.log('selectPoint', arrIdx, this.selPoint.showX, this.selPoint.showY);
                if(this.isQuickScore) this.$refs.rightPanel.scoreFocusByIndex(arrIdx)
            },
            changePoint(id, arrIdx, newRect, wenziText){
                // console.log("changePoint", id, arrIdx, newRect, wenziText);
                let selPoint = this.drawList[arrIdx];
                this.selPointIndex = arrIdx;
                let gu = 121;
                if(newRect != null){
                  let rect = this.myCanvas.getBoundingClientRect();
                  let cha = 0;
                  let x = newRect.left - rect.left;
                  let y = newRect.top;
                  let scaleX = x /  rect.width;
                  let scaleY = (y-gu) / rect.height;
                  if(scaleX < 0 || scaleX > 1) return;
                  if(scaleY < 0 || scaleY > 1) return;
                  selPoint.w = newRect.width;
                  selPoint.h = newRect.height;
                  selPoint.y = y;
                  selPoint.x = x;
                  selPoint.showX = newRect.left;
                  selPoint.showY = newRect.top;
                  selPoint.scaleX = scaleX;
                  selPoint.scaleY = scaleY;
                  selPoint["move"] = true
                  if(this.isEdit){
                    if(this.userInfo.id == this.selZgInfo.teacherId){
                      selPoint["power"] = 1;
                      selPoint["color"] = 'darkred';
                    }else{
                      selPoint["power"] = 2;
                      selPoint["color"] = 'indianred';
                    }
                  }
                  // console.log("changePoint", scaleX, scaleY, rect.height);
                }
                if(wenziText != null){
                    selPoint["wenziText"] = wenziText;
                    console.log("wenziText", wenziText, wenziText.length);
                    let pw =  wenziText.length * 25;
                    if(pw > selPoint.w) selPoint.w = pw;
                }
            },
            changeScore(id, arrIdx, tiScore, tiStep){
                let selPoint = this.drawList[arrIdx];
                this.selPointIndex = arrIdx;
                selPoint["score"] = tiScore;
                this.$refs.rightPanel.caidianScore(selPoint.topicIndex, tiStep);
            },
            openSearchDialog(){
              this.$refs.searchDialog.showDialog(this.searchIxList);
            },
            operOK(seq){
                this.drawMode = seq;
            },
            closeViewer(){
                this.showViewer = false;
            },
            rpChangeMode(mode, o){
                this.drawMode = mode;
                this.caiDian = o;
                if("operPanel" in this.$refs && this.$refs.operPanel != null) this.$refs.operPanel.unSelAll();
            },
            rpSubmitScore(o){
              let zgIds = this.selZgInfo.zgIds;
              console.log("rpSubmitScore", this.selZgInfo, this.drawList);
              let zgList = [];
              let isCd = false, cdScore = 0, gu = 121; //踩点分数
              let rect = this.myCanvas.getBoundingClientRect();
              let myHeight = document.documentElement.clientHeight
              let saveY = 0;
              if(rect.top>=0 && rect.top <=gu) saveY = parseInt(gu-rect.top);
              if(rect.top <0) saveY = parseInt(rect.top-gu);
              let beginHeight = 0, endHeight = 0, zgCount = zgIds.length;
              let rectLeft = rect.left, rectTop = rect.top, rectWidth = rect.width, rectHeight = rect.height;
              let zgMarkList = [];
                for (let i=0; i<zgCount; i++){
                  let zgId = zgIds[i];
                  let zgImg = this.selZgInfo["zg"+(i+1)];
                  if(i > 0) beginHeight = endHeight;
                  endHeight = endHeight  + zgImg.height;
                  for (let j=0; j<this.drawList.length; j++){
                    let _d = this.drawList[j];
                    let d = JSON.parse(JSON.stringify(_d));
                    d["blockIndex"] = i
                    if(!("move" in d)){
                      zgMarkList.push(d);
                      console.log("move", d);
                      continue;
                    }
                    let x = d.scaleX * rectWidth;
                    let y = d.scaleY * rectHeight + d.h/2;
                    console.log("submit for", d.x, d.y, zgImg.width, zgImg.height, rectLeft, rectTop, rectWidth, rectHeight);
                    if(d.type == 3 || d.type == 4){
                        if(x < zgImg.width && y >= beginHeight && y <= endHeight){
                            d["scaleX1"] = x / zgImg.width;
                            d["scaleY1"] = (d.scaleY * rectHeight-beginHeight) / zgImg.height; //d["scaleY1"] = (d.y-beginHeight) / zgImg.height;
                          if(d["scaleY1"] > 1) d["scaleY1"] = 1
                            if(d.type == 99) {cdScore = cdScore + d.score; isCd = true; }
                            zgMarkList.push(d);
                        }else{
                          if(zgCount == 1){
                            if(x >= zgImg.width) d["scaleX1"] = 1;
                            if(y >= endHeight) d["scaleY1"] = 1;
                            if(y < beginHeight) d["scaleY1"] = 0;
                            if(d.type == 99) {cdScore = cdScore + d.score; isCd = true; }
                            zgMarkList.push(d);
                          }
                        }
                    }else{
                        if(x < zgImg.width && y >= beginHeight && y <= endHeight){
                          console.log("contain_for", x, y, zgImg.width, zgImg.height, beginHeight, endHeight, rectLeft, rectTop, rectWidth, rectHeight);
                          d["scaleX1"] = x / zgImg.width;
                          d["scaleY1"] = (d.scaleY * rectHeight-beginHeight) / zgImg.height;
                          if(d["scaleY1"] > 1) d["scaleY1"] = 1
                          if(d.type == 99) {cdScore = cdScore + d.score; isCd = true; }
                          zgMarkList.push(d);
                        }else{
                          if(zgCount == 1){
                            if(x >= zgImg.width) d["scaleX1"] = 1;
                            if(y >= endHeight) d["scaleY1"] = 1;
                            if(y < beginHeight) d["scaleY1"] = 0;
                            if(d.type == 99) {cdScore = cdScore + d.score; isCd = true; }
                            zgMarkList.push(d);
                          }
                        }
                    }
                  }
                  let zgMark = { points: zgMarkList, scoreInfo: o};
                  if(this.topicType=='FillBlank') {
                    this.fillBlankList = []
                    zgMark["isQuickScore"] = this.isQuickScore
                    if(this.isQuickScore){
                      for (let k=0; k<this.drawList.length; k++) {
                        if (this.drawList[k].type == 100) {
                          let fobj = JSON.parse(JSON.stringify(this.drawList[k]))
                          fobj.score = 0
                          if("color" in fobj){ delete fobj.color }
                          this.fillBlankList.push(fobj);
                        }
                      }
                    }
                  }
                  if(this.isEdit) {zgMark["power"] = 1; zgMark["color"] = 'darkred';}
                  console.log("zgMarkList", beginHeight, endHeight, zgMark, zgMarkList);
                  let zg = {id: zgId, status: 'YY', score: o.score, markRecord: JSON.stringify(zgMark), teacherId: this.userInfo.id, drawImg: null };
                  zgList.push(zg);
                }

                console.log('zgList', zgList);
                if(isCd && cdScore>=0 && cdScore != o.score){
                    this.$alert('踩点打分总分:'+ cdScore+'，与计算分:'+ o.score + ' 不一致！', '提示');
                    return ;
                }
                let that = this;
                let param = { controllerName: 'examTask', methodName: '/updateZg', param: { examId: XEUtils.toNumber(this.examId), zgList: zgList}};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    if (code == 0) {
                        this.$message({message: '阅卷打分成功！', type: 'success', duration: 2000});
                        this.examImageList = [];
                        this.loadExam(function () {
                            that.loadExamTopic(function () {
                                that.loadMenu(function () {
                                    that.drawList = [];
                                    that.loadZgExam();
                                });
                            });
                        });

                    }else{
                        this.$alert('阅卷失败！' + data, '提示');
                        this.reload();
                    }
                    this.loading = false;
                });
            },
            openViewer(){
                this.showViewer = true;
            },
        },

    }
</script>

<style lang="scss" scoped type="text/scss" rel="stylesheet/scss">

    .scoring{
        .item {
            margin: 4px;
        }
        .el-header {
            background-color: #02b6fd;
            color: white;
            line-height: 60px;
            .icon {
                line-height: 60px;
                height: 30px;
                width: 30px;
                font-size: 26px;
            }
            .head {
                font-size: 16px;
                text-align: left;
            }
        }
        .el-aside {
            color: #333;
        }

    }



</style>


