<template>

  <vue-drag-resize  class="scorePoint" :class="{quickScore: type==100}" :isActive="false" :isDraggable="isDraggable" :isResizable="isResizable" :w="w" @resizestop="resizeStop" @activated="activateEv"
                   :h="h" :x="x" :y="y" :minw="1" :minh="1"  v-on:resizing="resize" v-on:dragging="resize" @clicked.prevent="myClick" @deactivated="deactivateEv">
    <div @mouseover="showDelete(true)" @mouseout="showDelete(false)">
      <div class="div-del" :class="{'div-del2': (type==12), 'div-del4': (type==4), 'div-del-inner': (type==3 || type==14 || type==16)}" v-show="isShowDelete">
        <i class="el-icon-delete" v-if="(type==3 || type==14 || type==16)" style="display: inline; color: red;" @click.stop="deleteMe"></i>
        <i class="el-icon-error" v-else style="display: inline; color: red;" @click.stop="deleteMe"></i>
      </div>

      <div class="check" v-if="type==1" :style="{ '--myColor': myColor}"></div>
      <div class="check-hard" v-if="type==11" :style="{'--myColor': myColor}"></div>
      <div class="tick-error" v-if="type==2" :style="{'--myColor': myColor}"></div>
      <div class="yi" v-if="type==12" :style="{ '--myColor': myColor}"></div>
      <div class="ellipse" :style="{width: w+'px', height: h+'px', '--myColor': myColor}" v-if="type==3"></div>
      <div class="juxing" :style="{width: w+'px', height: h+'px', '--myColor': myColor}" v-if="type==14"></div>
        <div class="wenhao" v-if="type==15" :style="{color: myColor, '--myColor': myColor}">?</div>
        <div class="shenglue" v-if="type==16" :style="{color: myColor, '--myColor': myColor}">...</div>
      <div class="wenzi" v-if="type==4" :style="{'--myColor': myColor}">
        <el-input v-model="wenziText" ref="drag-search" placeholder="" @blur="weiziChange" :style="textStyle" class="wenziTyle"></el-input>
      </div>
      <div class="ti-score" v-if="type==99" :style="{'--myColor': myColor}">
        <span :style="{color: myColor, fontSize: '20px', 'fontWeight': 'bold', display: 'inline'}">{{tiScore>0?"+"+tiScore:tiScore}}</span>
        <i class="el-icon-circle-plus" style="display: inline;" v-if="showScoreTool" @click.stop="changeScore(1)"></i>
        <i class="el-icon-remove" style="display: inline;" v-if="showScoreTool" @click.stop="changeScore(-1)"></i>
      </div>
    </div>
    <div class="quick-score" v-if="type==100" :style="{'--myColor': myColor}">
      <div class="topic-index">{{other.topicIndex}}.</div>
      <span :style="{color: myColor}" class="tip-score">{{tiScore>0?"+"+tiScore:tiScore}}</span>
    </div>
<!--    {{arrIdx}}({{x}},{{y}})-->
  </vue-drag-resize>

</template>

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import VueDragResize from 'vue-drag-resize';

  export default {
    name: 'scorePoint',
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
    },
    data() {
      return {
        selectElement: '', myStyle: null, squareStyle: {width: '15px', height: '15px', border: '1px solid red' },
        theDialogVisible: true, wenziText: null, textStyle: {width: '80px'}, showScoreTool: false, isShowDelete: false,
        pw: 0, ph: 0, px: 0, py: 0, myColor: 'red'
      }
    },
    components:{
      VueDragResize
    },
    watch: {
      type: {
        deep: true, immediate: true,
        handler(n, o) {
          if(n==4){
            console.log('this.other', this.other);
            this.wenziText = this.other.wenziText;
              this.textStyle = {width: this.w+'px'};
          }
        }
      },
      eColor: {
        deep: true, immediate: true,
        handler(n, o) {
          if(n){
            this.myColor = n;
          }else{
            this.myColor = 'red';
          }
        }
      }
    },
    props: {
      id: String,
      type: Number,
      arrIdx: Number,
      eColor: {
        type: String,
        default: 'red'
      },
      tiScore: {
        type: Number,
        required: false
      },
      other: {
        type: Object,
        required: false
      },
      h: {
        type: Number,
        default: 15,
      },
      w: {
        type: Number,
        default: 15,
      },
      x: Number,
      y: Number,
      isResizable: Boolean,
      isDraggable: Boolean
    },
    mounted: function(){

    },
    methods: {
      myClick(e){
        console.log('point click', e, e.offsetX)
        this.$emit('select', this.id, this.arrIdx);
        if(this.type == 100){
          this.$emit('quickScore', this.id, this.arrIdx);
        }
        // let cn = e.target.className, offsetX = e.offsetX;
        //
        // if(cn == "topic-index"){
        //   this.changeScore(-0.5)
        // }else if(cn == "tip-score"){
        //   this.changeScore(0.5)
        // }else{
        //
        // }
      },
      resizeStop(newRect){
        console.log("resizestop", newRect)
      },
      activateEv(){
        if(this.type == 4){
          this.$refs['drag-search'].focus();
        }
        if(this.type == 99){
          if(this.tiScore > 0) this.showScoreTool = true;
        }
      },
      deactivateEv(){
        if(this.type == 99){
          this.showScoreTool = false;
        }
      },
      weiziChange(e){
        this.$emit('repos', this.id, this.arrIdx, null, this.wenziText);
      },
      resize(newRect) {
        // console.log("resize", newRect)
        this.pw = newRect.width;
        this.ph = newRect.height;
        this.py = newRect.top;
        this.px = newRect.left;
        this.textStyle = {width: newRect.width+'px'};
        // console.log("newRect", this.textStyle);
        this.$emit('repos', this.id, this.arrIdx, newRect, this.wenziText);
      },
      showDelete(v){
        this.isShowDelete = v
      },
      deleteMe(){
        console.log("deleteMe", this.id)
        this.$emit('deleteMe', this.id, this.arrIdx);
      },
      changeScore(v){
        let step = this.other.scoreStep;
        if(v < 0) step = 0 - this.other.scoreStep;
        let tiScore = this.tiScore + step;
        if(tiScore < 0) return;
        this.tiScore = tiScore;
        this.$emit('changeScore', this.id, this.arrIdx, this.tiScore, step);
      }
    }
  }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

  .scorePoint {
    /*::v-deep .vdr .vdr.active:after{*/
    /*  outline: none;*/
    /*  background-color: #2a3542;*/
    /*}*/
    /*border: solid 1px red;*/
    z-index: 99;
    .div-del{
      position: absolute;
      right: -10px;
      top: -8px;
    }
    .div-del2{
      right: -10px;
      top: -15px;
    }
    .div-del4{
      right: 10px;
      top: -15px;
    }
    .div-del-inner{
      right: 40%;
      top: 40%;
    }
    .check {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 40px;
      /*background: red;*/
      /*border-radius: 25px;*/
    }
    .check::after {
      content: "";
      position: absolute;
      left: 2px;
      top: 8px;
      width: 80%;
      height: 25%;
      border: 4px solid var(--myColor);

      border-radius: 1px;
      border-top: none;
      border-right: none;
      background: transparent;
      transform: rotate(-45deg);
    }

    .check-hard {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 40px;
      /*background: red;*/
      /*border-radius: 25px;*/
    }
    .check-hard::after {
      content: "";
      position: absolute;
      left: 2px;
      top: 8px;
      width: 80%;
      height: 25%;
      border: 4px solid var(--myColor);
      border-radius: 1px;
      border-top: none;
      border-right: none;
      background: transparent;
      transform: rotate(-45deg);
    }
    .check-hard::before {
      position: absolute;
      content: ' ';
      background-color: var(--myColor);
      left: 12px;
      top: 10px;
      width: 4px;
      height: 10px;
    }
    .check-hard::before {
      transform: rotate(-45deg);
    }
    .yi{
      background-color: var(--myColor);
      height: 2px;
    }
      .wenhao{
          color: var(--myColor);
          font-size: 36px;
          height: 36px;
      }
      .shenglue{
          color: var(--myColor);
          font-size: 36px;
          height: 16px;
      }
    .tick-error {
      position: relative;
      width: 40px;
      height: 40px;
    }
    .tick-error::before,
    .tick-error::after {
      position: absolute;
      content: ' ';
      background-color: var(--myColor);
      left: 18px;
      width: 3px;
      height: 40px;
    }

    .tick-error::before {
      transform: rotate(45deg);
    }

    .tick-error::after {
      transform: rotate(-45deg);
    }
    .wenzi {
      width: 80px;
      input {color:var(--myColor);}

      .wenziTyle{
        font-size: 18px;
        color: var(--myColor);
        height: 40px;
        ::-webkit-input-placeholder {
          color: var(--myColor);
        }
        :-moz-placeholder {
          color: var(--myColor);
        }
        ::-moz-placeholder{
          color: var(--myColor);
        }
        :-ms-input-placeholder {
          color: var(--myColor);
        }
      }
    }
    .ti-score {


    }
    .quick-score{
      position: relative;
      .topic-index{
        position: absolute;
        left: 5px;
        top: 1px;
        font-size: 18px;
        color: red;
      }
      .tip-score{
        color: red;
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px;
        font-weight: bold;
        display: inline;
      }
    }

    .ellipse{
      /*margin: 50px;*/
      background: transparent;
      border: 2px solid var(--myColor);
      border-radius: 50% / 50%;
    }
    .juxing{
      background: transparent;
      border: 2px solid var(--myColor);
    }

  }
  .quickScore{
    border: #1c6ca1 2px dotted;
  }



</style>
